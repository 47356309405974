.button {
  @apply transition ease-in-out duration-200 inline-block cursor-pointer disabled:cursor-not-allowed font-normal leading-4 text-sm focus-visible:outline-none rounded-lg whitespace-nowrap;
}
.button--default {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.button--primary {
  @apply bg-accent hover:bg-accent/80 text-white border border-accent;
}
.button--secondary {
  @apply text-gray-800 border border-gray-500 bg-gray-100 hover:bg-gray-200;
}
.button--alert {
  @apply text-red-500 dark:text-red-500 border border-red-500 dark:border-red-500 dark:hover:border-red-600;
}
.button--success {
  color: #17203a;
  background-color: #56e214;
  border: #56e214;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.button--text {
  @apply ease-out duration-200 outline-none transition-all outline-0 focus-visible:outline-4 focus-visible:outline-offset-1 border hover:bg-gray-50 shadow-none focus-visible:outline-none border-transparent text-xs px-2.5 py-1;
}
.button--small {
  @apply text-xs py-1.5 px-3;
}
.button--medium {
  @apply py-2.5 px-4;
}
.button--large {
  @apply text-base py-3 px-5;
}

:disabled {
  opacity: 0.4;
  cursor: default;
}
