@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hero-image {
  background-image: url("./assets/hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-image {
  box-shadow: -30px 30px 0px 0px #fec10e;
  width: 80%;
  margin-left: 30px;
}

.home-quote-section {
  background-image: url("./assets/quote.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.rc-slider-rail,
.rc-slider-track,
.rc-slider-tracks {
  height: 8px !important;
}

.rc-slider-track,
.rc-slider-tracks {
  background-color: rgba(255, 230, 178, 1) !important;
}

.rc-slider-dot-active,
.rc-slider-handle {
  border-color: #fec10e !important;
}

.rc-slider-handle {
  height: 18px !important;
  width: 18px !important;
}

.rc-slider-dot {
  bottom: -6px !important;
  height: 12px !important;
  width: 12px !important;
}

.rc-slider-mark-text {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.rc-slider-mark-text,
.rc-slider-mark-text-active {
  margin-top: 6px;
  color: #000 !important;
}

.blur-backdrop {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.rc-slider-handle:focus-visible {
  border-color: #fec10e !important;
  box-shadow: 0 0 0 3px #fec10e !important;
}

.carousel .slide img {
  border-radius: 50px;
}
